
export default {
  name: 'SplitTestContainer',
  components: {
    TestVariant: () => import('@/components/splitTesting/TestVariant')
  },
  props: {
    blok: {
      required: true,
      type: Object
    }
  }
}
